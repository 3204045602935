export interface EmberApiError {
  // User-presentable error message
  message: string

  // A string identifying the type of error. This is not typically shown to the
  // user
  type?: string

  // HTTP status code of the response that carried the error. If we could not
  // connect to the server, this will be 0.
  httpStatusCode?: number

  // Response payload (e.g. JSON object) of the HTTP response, if any.
  payload?: any
}

export function parseError(response): EmberApiError {
  const type = response?.error?.response?.errorType
  const httpStatusCode = response?.error?.status ?? -1

  // The API returns errors as `response.error.response?.message`, but lower-level errors such as
  // network problems will return `response.error.message`.
  //
  // When there is no structured error response (network errors, complete backend crashes) the RxJS
  // AJAX module returns messages like "ajax error 404". We replace those with one of the generic
  // messages defined below in _defaultErrorMessage
  //
  let message = response?.error?.response?.message || response?.error?.message
  if (!message || `${message}`.match(/^ajax error(?: \d\d\d)?$/)) {
    message = _defaultErrorMessage(type, httpStatusCode)
  }

  return {
    message,
    type,
    httpStatusCode,
    payload: response?.error?.response?.payload,
  }
}

function _defaultErrorMessage(
  errorType: string | null,
  httpStatusCode: number
) {
  const message = getApologyMessageForHttpCode(httpStatusCode)
  if (message) {
    return message
  }
  const details = `${httpStatusCode > 0 ? httpStatusCode : "network"} error`
  return `${errorType || "An unknown error"} occurred (${details})`
}

export function getApologyMessageForHttpCode(
  httpStatusCode: number
): string | null {
  switch (httpStatusCode) {
    case -1:
    case 0:
      return "Could not connect to server. Check your internet connection and try refreshing"
    case 500:
      return "An error has occurred. Our engineers have been alerted."
    case 502:
      return "Our system is under heavy load. Please wait and try again"
    case 504:
      return "Our system took too long to reply. Please wait and try again"
    default:
      return null
  }
}

export function parseErrorMessage(response): string {
  return parseError(response).message
}

function isEmberApiError(error: any): error is EmberApiError {
  return error && typeof error === "object" && "message" in error
}

export function extractErrorMessage(error: any): string {
  return isEmberApiError(error) ? error.message : "An error occurred"
}
