import getTokens from "@kiwicom/orbit-components/lib/getTokens"

import emberTheme from "./ember-theme"

export const lightThemeFoundation = {
  palette: {
    product: {
      light: "#EFF3F3",
      lightHover: "#e2eeee",
      lightActive: "#e2eeee",
      normal: "#4F917A",
      normalHover: "#45816c",
      normalActive: "#45816c",
      dark: "#238078",
      darkHover: "#1F7069",
      darkActive: "#195C58",
      darker: "#004D40",
    },
    white: {
      normal: "#FFFFFF",
      normalHover: "#F1F4F7",
      normalActive: "#E7ECF1",
    },
    cloud: {
      light: "#F5F7F9",
      lightHover: "#E5EAEF",
      lightActive: "#D6DEE6",
      normal: "#e4e8ed",
      normalHover: "#DCE3E9",
      normalActive: "#CAD4DE",

      // This one sets the colour of the border on text boxes, the outline of radio checkbox
      // circles, etc
      dark: "#BAC7D5",
    },
    ink: {
      lighter: "#BAC7D5",
      lighterHover: "#A6B6C8",
      lighterActive: "#94A8BE",
      light: "#6a737d",
      lightHover: "#5d656e",
      lightActive: "#505861",
      normal: "#2F3136",
      normalHover: "#1B1C1f",
      normalActive: "#101012",
    },
    orange: {
      light: "#FDF0E3",
      lightHover: "#FAE2C7",
      lightActive: "#F8D3AB",
      normal: "#F9971E",
      normalHover: "#F38906",
      normalActive: "#D67906",
      dark: "#AB3307",
      darkHover: "#972C07",
      darkActive: "#7A2405",
      darker: "#842706",
    },
    red: {
      light: "#FAEAEA",
      lightHover: "#F4D2D2",
      lightActive: "#EEB9B9",
      normal: "#D3455A",
      normalHover: "#B91919",
      normalActive: "#9D1515",
      dark: "#970C0C",
      darkHover: "#890B0B",
      darkActive: "#6D0909",
      darker: "#760909",
    },
    green: {
      light: "#EBF4EC",
      lightHover: "#D7EAD9",
      lightActive: "#C3DFC7",
      normal: "#28A138",
      normalHover: "#238B31",
      normalActive: "#1D7228",
      dark: "#2D7738",
      darkHover: "#276831",
      darkActive: "#1F5126",
      darker: "#235C2B",
    },
    blue: {
      light: "#E5F7FF",
      lightHover: "#C7EEFF",
      lightActive: "#A8E5FF",
      normal: "#0172CB",
      normalHover: "#0161AC",
      normalActive: "#01508E",
      dark: "#005AA3",
      darkHover: "#004F8F",
      darkActive: "#003E70",
      darker: "#004680",
    },
    social: {
      facebook: "#1778F2",
      facebookHover: "#0C69DE",
      facebookActive: "#0B5CC1",
    },
  },
  fontFamily: {
    base: "'Montserrat','Helvetica','Arial',sans-serif",
  },
  fontSize: {
    small: "14px",
    normal: "16px",
    large: "18px",
  },
  borderRadius: {
    normal: "3px",
  },
  size: {
    small: "16px",
    medium: "24px",
    large: "38px",
    extraLarge: "48px",
    extraExtraLarge: "52px ",
  },
  // opacitySmall: "0.3",
  // opacityMedium: "0.5",
  // opacityLarge: "0.8",
  fontWeight: {
    normal: "400",
    medium: "500",
    bold: "700",
  },
  space: {
    XXSmall: "4px",
    XSmall: "8px",
    small: "12px",
    medium: "16px",
    large: "24px",
    XLarge: "32px",
    XXLarge: "40px",
    XXXLarge: "52px",
  },
  // durationFast: "0.15s",
  // durationNormal: "0.3s",
  // durationSlow: "0.4s",
  // transitionDefault: "ease-in-out",
  lineHeight: { normal: "1.4" },
}

const tokens = getTokens(lightThemeFoundation)

tokens.lineHeightTextSmall = "20px"
tokens.lineHeightTextNormal = "22px"
tokens.lineHeightTextLarge = "24px"
tokens.fontSizeFormLabel = "14px"
tokens.fontSizeHeadingTitle3 = "18px"

const theme = {
  ember: emberTheme,
  orbit: tokens,
}

export default theme
