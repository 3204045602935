import { FlatLocationTime } from "./location"
import { Tickets } from "./pass"
import { Timing } from "./timing"
import { TripType } from "./trip"

export enum ConfirmationMethod {
  QR_CODE = "QR_CODE",
  NFC = "NFC",
  ONBOARD_SALE = "ONBOARD_SALE",
  MANUAL = "MANUAL",
}

export interface BoardingData {
  date: Date
  confirmationMethod: ConfirmationMethod
  tripId: number
  checkedIn: Tickets
  noShow: Tickets
}

export enum LegType {
  SCHEDULED_TRANSIT = "scheduled_transit",
  FREQUENT_TRANSIT = "frequent_transit",
  ACTIVE_TRAVEL = "active_travel",
}

export interface Leg {
  origin: FlatLocationTime
  destination: FlatLocationTime
  departure: Timing
  arrival: Timing
  description:
    | ScheduledTransitDescription
    | FrequentTransitDescription
    | ActiveTravelDescription
  boardingData: BoardingData | null
  type: LegType
  tripNotes?: TripNotes
  tripType?: TripType // NB only included in legs returned by the quotes endpoint
  tripUid?: string
  addsCapacityForTripUid?: string
}

export interface ActiveTravelDescription {
  type: "walk"
}

export interface ScheduledTransitDescription {
  operator: string
  destinationBoard?: string
  numberPlate?: string
  vehicleType: string
  colour?: string
  isElectric: boolean
}

export interface FrequentTransitDescription {
  operator: string
  destinationBoard: string
  frequency: number
  vehicleType: string
}

interface TripNotes {
  content: string
  isPublic: boolean
  updatedAt: string
  renderedContent: string
}
