import { EmberApiError, parseError } from "api/errors"

import { Conversation, Message } from "types/chat"
import { Unsubscribable } from "types/observable"
import { Profile } from "types/person"

import { fetchFromAPIBase } from "utils/fetch-utils"
import { getPersonName } from "utils/name-utils"
import { sortBy } from "utils/struct-utils"

interface fetchChatUsersParams {
  onSuccess: (users: Profile[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchChatUsers({
  onSuccess,
  onError,
}: fetchChatUsersParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/chat/users/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      const people = response
      onSuccess(sortBy(people, getPersonName))
    } else {
      onError(parseError(response))
    }
  })
}

interface MessagePayload {
  bodyMd: string
}

interface addMessageParams {
  conversationUid: string
  payload: MessagePayload
  onSuccess: (message: Message) => void
  onError: (error: EmberApiError) => void
}

export function addMessage({
  conversationUid,
  payload,
  onSuccess,
  onError,
}: addMessageParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/chat/conversations/${conversationUid}/`,
    method: "POST",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.message)
    } else {
      onError(parseError(response))
    }
  })
}

interface editMessageParams {
  conversationUid: string
  messageUid: string
  payload: MessagePayload
  onSuccess: (message: Message) => void
  onError: (error: EmberApiError) => void
}

export function editMessage({
  conversationUid,
  messageUid,
  payload,
  onSuccess,
  onError,
}: editMessageParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/chat/conversations/${conversationUid}/messages/${messageUid}/`,
    method: "PUT",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.message)
    } else {
      onError(parseError(response))
    }
  })
}

interface deleteMessageParams {
  conversationUid: string
  messageUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function deleteMessage({
  conversationUid,
  messageUid,
  onSuccess,
  onError,
}: deleteMessageParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/chat/conversations/${conversationUid}/messages/${messageUid}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}

interface fetchConversationParams {
  conversationUid: string
  onSuccess: (conversation: Conversation) => void
  onError: (error: EmberApiError) => void
}

export function fetchConversation({
  conversationUid,
  onSuccess,
  onError,
}: fetchConversationParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/chat/conversations/${conversationUid}/`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.conversation)
    } else {
      onError(parseError(response))
    }
  })
}

interface acknowledgeRevisionParams {
  conversationUid: string
  revision: number
  onSuccess?: () => void
  onError?: (error: EmberApiError) => void
}

export function acknowledgeRevision({
  conversationUid,
  revision,
  onSuccess = () => null,
  onError = () => null,
}: acknowledgeRevisionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/chat/conversations/${conversationUid}/acknowledge/`,
    method: "POST",
    body: { revision },
  }).subscribe((response) => {
    if (response?.error) {
      onError(parseError(response))
    } else {
      onSuccess()
    }
  })
}
