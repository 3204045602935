import { decamelize } from "humps"

import { EmberApiError, parseError } from "api/errors"

import {
  Issue,
  IssueCategory,
  IssueSeverity,
  IssueStatus,
  IssueSummary,
  MaintenancePermissions,
} from "types/issue"
import { Unsubscribable } from "types/observable"
import { Profile } from "types/person"
import { UploadedFile } from "types/uploaded-file"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { sortBy } from "utils/struct-utils"

export function fetchIssueByUid({
  issueUid,
  onSuccess,
  onError,
}: {
  issueUid: string
  onSuccess: (issue: Issue) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/maintenance/issues/${issueUid}/`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess({
        ...response.issue,
        subscribed: response.subscribed,
      })
    } else {
      onError(parseError(response))
    }
  })
}

export enum IssueSelectionPreset {
  NEW_OR_OVERDUE = "new_or_overdue",
}

export interface IssueQuery {
  // If adding fields here that can be filtered on by the user on the issue table page, consider
  // also adding them to `urlStoreForIssues` in `components/maintenance/issues/filters.tsx` so that
  // they're mirrored in the URL on the issue list page
  preset?: IssueSelectionPreset
  vehicleId?: number[]
  severity?: IssueSeverity[]
  categoryUid?: string[]
  status?: IssueStatus[]
  assigneeUid?: string[]
  hideSnoozed?: boolean
  textQuery?: string
  isDefect?: boolean
  limit?: number
  sortColumnId?: string
  sortReversed?: boolean
}

export interface fetchIssuesParams {
  query: IssueQuery
  onSuccess: (issues: IssueSummary[], total: number) => void
  onError: (error: EmberApiError) => void
}

export function fetchIssues({
  query = {},
  onSuccess,
  onError,
}: fetchIssuesParams): Unsubscribable {
  return fetchFromAPIBase({
    path:
      "/v1/maintenance/issues/" +
      getQueryString({
        ...query,
        sortColumnId: query.sortColumnId && decamelize(query.sortColumnId),
      }),
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.issues, response.total)
    } else {
      onError(parseError(response))
    }
  })
}

export interface IssuePayload {
  severity: string
  categoryUid?: string
  status?: string
  title: string
  assigneeUid?: string
  descriptionMd: string
  duplicateOfUid?: string
  subscribe?: boolean
  vehicleId: number
  attachments?: UploadedFile[]
  snoozedUntil?: string
  resolutionNote?: string
  activityIds?: number[]
}

export function submitNewIssue({
  payload,
  onSuccess,
  onError,
}: {
  payload: IssuePayload
  onSuccess: (newIssue: Issue) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/maintenance/issues/",
    method: "POST",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess({
        ...response.issue,
        subscribed: response.subscribed,
      })
    } else {
      onError(parseError(response))
    }
  })
}

export function modifyIssue({
  issue,
  changes,
  onSuccess,
  onError,
}: {
  issue: Issue
  changes: Partial<IssuePayload>
  onSuccess: (updatedIssue: Issue) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  const value = (key, fallback) => (key in changes ? changes[key] : fallback)
  return fetchFromAPIBase({
    path: `/v1/maintenance/issues/${issue.uid}/`,
    method: "PUT",
    body: {
      severity: value("severity", issue.severity),
      categoryUid: value("categoryUid", issue.category?.uid),
      status: value("status", issue.status),
      title: value("title", issue.title),
      assigneeUid: value("assigneeUid", issue.assignee?.uid),
      descriptionMd: value("descriptionMd", issue.descriptionMd),
      duplicateOfUid: value("duplicateOfUid", issue.duplicateOf?.uid),
      vehicleId: value("vehicleId", issue.vehicle?.id),
      snoozedUntil: value("snoozedUntil", issue.snoozedUntil),
      resolutionNote: value("resolutionNote", issue.resolutionNote),
      attachments: value("attachments", issue.attachments).map((f) => f.uid),
      activityIds: value(
        "activityIds",
        issue.activities.map((a) => a.id)
      ),
    },
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess({
        ...response.issue,
        subscribed: response.subscribed,
      })
    } else {
      onError(parseError(response))
    }
  })
}

export function fetchIssueCategories({
  onSuccess,
  onError,
}: {
  onSuccess: (issueCategories: IssueCategory[]) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/maintenance/issue-categories/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(sortBy(response.categories, sortableName))
    } else {
      onError(parseError(response))
    }
  })
}

export function fetchMaintenancePermissions({
  onSuccess,
  onError,
}: {
  onSuccess: (permissions: MaintenancePermissions) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/maintenance/permissions/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.permissions)
    } else {
      onError(parseError(response))
    }
  })
}

export function fetchUsersAssignableToIssues({
  onSuccess,
  onError,
}: {
  onSuccess: (usersAssignableToIssues: Profile[]) => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/maintenance/assignable-people/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.assignablePeople)
    } else {
      onError(parseError(response))
    }
  })
}

export function toggleIssueSubscription({
  issueUid,
  subscribe,
  onSuccess,
  onError,
}: {
  issueUid: string
  subscribe: boolean
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/maintenance/issues/${issueUid}/subscription/`,
    method: subscribe ? "PUT" : "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}

function sortableName(category: IssueCategory): string {
  const name = category.name.toLowerCase()
  if (name == "other") {
    return "{this goes last}"
  }
  return name
}
