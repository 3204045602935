import { Unsubscribable } from "types/observable"
import {
  ContractType,
  DriverDetails,
  Profile,
  VehicleEligibility,
} from "types/person"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"
import { getPersonName } from "utils/name-utils"
import { sortBy } from "utils/struct-utils"

import { EmberApiError, parseError } from "./errors"
import { PeopleSearchRequest } from "./person"

interface fetchDriversParams {
  request?: PeopleSearchRequest
  onSuccess: (drivers: Profile[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchDrivers({
  request = {},
  onSuccess,
  onError,
}: fetchDriversParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/drivers/${getQueryString(request)}`,
    authRequired: true,
  }).subscribe((response) => {
    if (response && !response.error) {
      const drivers = response as Profile[]
      sortBy(drivers, (d) => (getPersonName(d) ?? "zz").toLowerCase())
      onSuccess(drivers)
    } else {
      onError(parseError(response))
    }
  })
}

// `fetchDriverDetails` -- fetch the `DriverDetails` record for a given driver

interface fetchDriverDetailsParams {
  driverUid: string
  onSuccess: (details: DriverDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchDriverDetails({
  driverUid,
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchDriverDetailsParams): Unsubscribable {
  const query = getQueryString({ all: true })
  return fetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/${query}`,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

interface addVehicleEligibilityParams {
  driverUid: string
  body: Omit<VehicleEligibility, "id">
  onSuccess: (details: DriverDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function addVehicleEligibility({
  driverUid,
  body,
  onSuccess,
  onError,
  onCompletion = () => null,
}: addVehicleEligibilityParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/vehicles/`,
    method: "POST",
    authRequired: true,
    body,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

interface patchVehicleEligibilityParams {
  driverUid: string
  eligibilityId: number
  body: Omit<Partial<VehicleEligibility>, "id">
  onSuccess: (details: DriverDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function patchVehicleEligibility({
  driverUid,
  eligibilityId,
  body,
  onSuccess,
  onError,
  onCompletion = () => null,
}: patchVehicleEligibilityParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/vehicles/${eligibilityId}/`,
    method: "PATCH",
    authRequired: true,
    body,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

interface deleteVehicleEligibilityParams {
  driverUid: string
  eligibilityId: number
  onSuccess: (details: DriverDetails) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function deleteVehicleEligibility({
  driverUid,
  eligibilityId,
  onSuccess,
  onError,
  onCompletion = () => null,
}: deleteVehicleEligibilityParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/vehicles/${eligibilityId}/`,
    method: "DELETE",
    authRequired: true,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}

export interface fetchContractTypesParams {
  onSuccess: (types: ContractType[]) => void
  onError: (error: EmberApiError) => void
  onCompletion?: () => void
}

export function fetchContractTypes({
  onSuccess,
  onError,
  onCompletion = () => null,
}: fetchContractTypesParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/drivers/contract-types",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
    onCompletion()
  })
}
