import { Leg } from "./leg"

export interface Tickets {
  adult: number
  concession: number
  child: number
  youngChild: number
  wheelchair: number
  bicycle: number
}

export enum PassStatus {
  // This mirrors `PassStatus` in `ember_api/ticketing/models.py`
  INCOMPLETE = "incomplete",
  VALID = "valid",
  CANCELLED = "cancelled",
  PAYMENT_FAILED = "payment_failed",
}

export interface Pass {
  id: number
  code: string
  name: string
  status: PassStatus
  price: number
  tickets: Tickets
  legs: Leg[]
  cancellable: boolean
  changeable: boolean
  customerUid?: string
  orderDate: string
  passCreationTime: string
  version: number
}

// The /v1/trips/${tripId}/ endpoint returns these:
export interface PassWithOrderUid extends Pass {
  orderUid: string
}
