// @ts-strict-ignore
import { MinimalProfile, Person, Profile } from "types/person"

export type NamedPerson = Profile | Person | MinimalProfile

export function getPersonName(person: NamedPerson): string {
  if (person) {
    if (person.preferredName) {
      return person.preferredName
    } else if (person.name) {
      return person.name
    } else if ("email" in person) {
      // `email` is not in the `MinimalProfile` type
      return person.email
    } else {
      return person.uid
    }
  }
}
