// @ts-strict-ignore
import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"

import { Auth } from "@aws-amplify/auth/lib/Auth"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { fetchChatUsers } from "api/chat"
import { fetchChecklistTypes } from "api/checklists"
import { fetchContractTypes, fetchDrivers } from "api/drivers"
import { EmberApiError } from "api/errors"
import {
  fetchIssueCategories,
  fetchMaintenancePermissions,
  fetchUsersAssignableToIssues,
} from "api/issues"
import { fetchLocations } from "api/locations"
import { searchPeople } from "api/person"
import { fetchRoutes } from "api/routes"
import { fetchRotaVisibilityDate, fetchServiceProviders } from "api/shifts"

import { ServiceProvider } from "types/activity"
import { ApiCallFunction } from "types/api"
import { Basket } from "types/basket"
import { IssueCategory, MaintenancePermissions } from "types/issue"
import { Location, Route } from "types/location"
import { Pass } from "types/pass"
import { Balance, ContractType, Profile, UserGroup } from "types/person"
import { QuoteType } from "types/quote"
import { SearchParamsType } from "types/search"
import { RotaVisibilityDate } from "types/shift-detail"

import { bookingFromDate } from "./date-utils"
import { fetchFromAPIBase } from "./fetch-utils"
import { setGlobalDispatch, setGlobalState } from "./global-state"
import { getPersonName } from "./name-utils"

// Make available for tests
if (typeof window !== "undefined" && window["Cypress"]) {
  window["authModule"] = Auth
}

const queryClient = new QueryClient()

const stateLogger = (state, action, nextState) => {
  const table = {}
  Object.entries(state).map((item) => {
    table[item[0]] = {
      prevState: typeof item[1] == "object" ? JSON.stringify(item[1]) : item[1],
    }
  })
  Object.entries(nextState).map((item) => {
    table[item[0]].nextState =
      typeof item[1] == "object" ? JSON.stringify(item[1]) : item[1]
  })

  if (
    (typeof window !== "undefined" && window["Cypress"]) ||
    (typeof process !== "undefined" &&
      JSON.parse(process.env.GATSBY_DEBUG ?? ""))
  ) {
    console.group(
      "%cAction",
      "color:#00a8f7;font-size:1.3em;font-weight:600;",
      action
    )
    console.table(table)
    console.log(
      "%cprev state",
      "color:grey;font-size:1.1em;font-weight:600;",
      state
    )
    console.log(
      "%cnext state",
      "color:#47b14b;font-size:1.1em;font-weight:600;",
      nextState
    )

    console.groupEnd()
  }
}

/**
 * The `ItemFetcher` class is for objects that are stored in global state, shared with all pages,
 * but are only fetched on demand.
 *
 * Components that require the data can call `dispatch({ shouldFetch: "<fetcher-id>" })`, and the
 * request will be made.
 */
export class ItemFetcher<T> {
  apiCall: ApiCallFunction<T>
  data: T
  shouldFetch: boolean
  fetching: boolean
  errorMessage: string
  lastFetched?: Date

  constructor(
    apiCall: ApiCallFunction<T>,
    options: { shouldFetchOnLoad?: boolean; defaultValue?: T } = {}
  ) {
    this.apiCall = apiCall
    this.shouldFetch = options.shouldFetchOnLoad ?? false
    this.data = options.defaultValue // will be `undefined` if not specified
    this.fetching = false
    this.errorMessage = null
  }
}

export type FetchersType = {
  serviceProviders: ItemFetcher<ServiceProvider[]>
  chatUsers: ItemFetcher<Profile[]>
  usersAssignableToIssues: ItemFetcher<Profile[]>
  drivers: ItemFetcher<Profile[]>
  ancillaryStaff: ItemFetcher<Profile[]>
  locations: ItemFetcher<Location[]>
  rotaVisibilityDate: ItemFetcher<RotaVisibilityDate>
  routes: ItemFetcher<Route[]>
  issueCategories: ItemFetcher<IssueCategory[]>
  maintenancePermissions: ItemFetcher<MaintenancePermissions>
  checklistTypes: ItemFetcher<string[]>
  contractTypes: ItemFetcher<ContractType[]>
}

export type StateType = {
  fetchers: FetchersType

  didUserScroll: boolean

  // Search bar Parameters

  searchbarIsCollapsed: boolean
  searchParams: SearchParamsType
  searchModified: boolean

  // Book Page Parameters

  outboundQuotes: QuoteType[]
  returnQuotes: QuoteType[]
  outboundBasket: Basket
  outboundBasketOrigin: number
  outboundBasketDestination: number
  returnBasket: Basket
  returnBasketOrigin: number
  returnBasketDestination: number
  initialOrder: Pass[]
  initialOrderUid: string

  // Customer account

  openLoginResultDialog: boolean
  loginErrorMessage: string

  shouldRequestAccount: boolean
  fetchingAccount: boolean

  loggedIn: boolean
  groups: UserGroup[]
  loggedInPersonUid?: string

  profile: Profile
  balance: Balance
}

const reducer = (
  state: StateType,
  action: Partial<StateType> | string
): StateType => {
  const nextState = Object.assign({}, state)
  Object.keys(action).forEach((key) => {
    if (key == "shouldFetch") {
      const fetcherId = action[key]
      nextState.fetchers[fetcherId].shouldFetch = true
    } else {
      nextState[key] = action[key]
    }
  })
  stateLogger(state, action, nextState)
  return nextState
}

export const GlobalStateContext = createContext({} as StateType)
const GlobalDispatchContext = createContext({} as Dispatch<any>)

export const useGlobalState = () => useContext(GlobalStateContext)
export const useGlobalDispatch = () => useContext(GlobalDispatchContext)
export const useGlobalSetter = (name) => {
  const dispatch = useGlobalDispatch()
  return (value) => {
    const nextValue = {}
    nextValue[name] = value
    dispatch(nextValue)
  }
}

export const initialState: StateType = {
  fetchers: {
    serviceProviders: new ItemFetcher<ServiceProvider[]>(fetchServiceProviders),
    chatUsers: new ItemFetcher<Profile[]>(fetchChatUsers),
    usersAssignableToIssues: new ItemFetcher<Profile[]>(
      fetchUsersAssignableToIssues
    ),
    drivers: new ItemFetcher<Profile[]>(fetchDrivers, {
      defaultValue: [],
    }),
    ancillaryStaff: new ItemFetcher<Profile[]>(
      ({ onSuccess, onError }) => {
        return searchPeople({
          request: { roleNames: ["maintenance", "hub-support"] },
          onSuccess,
          onError,
        })
      },
      {
        defaultValue: [],
      }
    ),
    locations: new ItemFetcher(fetchLocations, {
      // 2024-05-03 - until today we used to always pre-emptively fetch locations onload (we had
      // `shouldFetchOnLoad: true` here), but after running into a bug where the maintenance issue
      // page got rate-limited because it was trying to load too many things at once, we agreed this
      // wasn't needed, and removed it. Locations are now loaded when first needed, like the rest
      defaultValue: [],
    }),
    rotaVisibilityDate: new ItemFetcher<RotaVisibilityDate>(
      fetchRotaVisibilityDate
    ),
    routes: new ItemFetcher(fetchRoutes, {
      defaultValue: [],
    }),
    issueCategories: new ItemFetcher<IssueCategory[]>(fetchIssueCategories),
    maintenancePermissions: new ItemFetcher<MaintenancePermissions>(
      fetchMaintenancePermissions
    ),
    checklistTypes: new ItemFetcher<string[]>(fetchChecklistTypes),
    contractTypes: new ItemFetcher<ContractType[]>(fetchContractTypes, {
      defaultValue: [],
    }),
  },

  didUserScroll: false,

  // Search Defaults

  searchParams: {
    origin: 13,
    destination: 42,
    outDate: bookingFromDate(),
    returnDate: null,
    adult: 1,
    concession: 0,
    child: 0,
    youngChild: 0,
    wheelchair: 0,
    bicycle: 0,
  },

  // Other Search Bar Stuff

  searchbarIsCollapsed: true,
  searchModified: false,

  // Book Page Params

  outboundQuotes: null,
  returnQuotes: null,
  outboundBasket: null,
  outboundBasketOrigin: null,
  outboundBasketDestination: null,
  returnBasket: null,
  returnBasketOrigin: null,
  returnBasketDestination: null,
  initialOrder: null,
  initialOrderUid: null,

  // User Info
  loggedIn: null,
  groups: null,
  loggedInPersonUid: null,

  // Customer account
  profile: null,
  balance: null,
  // orders: null,
  shouldRequestAccount: true,
  fetchingAccount: false,

  openLoginResultDialog: false,
  loginErrorMessage: null,
}

setGlobalState(initialState)

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  setGlobalState(state)
  setGlobalDispatch(dispatch)

  // Set didUserScroll parameter once page scrolled
  useEffect(() => {
    if (!state.didUserScroll) {
      // eslint-disable-next-line no-inner-declarations
      function scrolled() {
        window.removeEventListener("scroll", scrolled)
        dispatch({ didUserScroll: true })
      }
      window.addEventListener("scroll", scrolled)
    }
  }, [])

  // Request account when shouldRequestAccount set (including on load)
  useEffect(() => {
    if (state.shouldRequestAccount && !state.fetchingAccount) {
      dispatch({ fetchingAccount: true })
      setTimeout(
        () => {
          Auth.currentUserInfo().then((user) => {
            if (user) {
              Auth.currentSession().then((session) => {
                const payload = session.getAccessToken().payload
                const groups = payload["cognito:groups"]
                  ? payload["cognito:groups"]
                  : []
                dispatch({
                  loggedIn: true,
                  groups: groups,
                  loggedInPersonUid: payload.username,
                })
                const sub = fetchFromAPIBase({
                  path: "/v1/accounts/?profile=true&balance=true",
                  method: "GET",
                  authRequired: true,
                }).subscribe({
                  next: (response) => {
                    if (response && !response.error) {
                      dispatch(response)
                      // Update Front identity
                      if (
                        typeof window !== "undefined" &&
                        window["FrontChat"]
                      ) {
                        window["FrontChat"]("identity", {
                          email: response.profile.email,
                          name: getPersonName(response.profile),
                        })
                      }
                    }
                  },
                  complete: () => {
                    dispatch({
                      shouldRequestAccount: false,
                      fetchingAccount: false,
                    })
                  },
                })
                return () => sub.unsubscribe()
              })
            } else {
              dispatch({
                loggedIn: false,
                groups: null,
                shouldRequestAccount: false,
                fetchingAccount: false,
              })
            }
          })
        },
        window["Cypress"] ? 1000 : 0
      )
    }
  }, [state.shouldRequestAccount])

  useEffect(
    () => {
      Object.entries(state.fetchers).forEach(([fetcherId, fetcher]) => {
        if (fetcher.shouldFetch && !fetcher.fetching) {
          const nextFetcherState = Object.assign({}, state.fetchers)
          const nextFetcher = nextFetcherState[fetcherId]
          nextFetcher.fetching = true
          nextFetcher.errorMessage = ""
          dispatch({ fetchers: nextFetcherState })
          fetcher.apiCall({
            onSuccess: (data) => {
              nextFetcher.shouldFetch = false
              nextFetcher.fetching = false
              nextFetcher.lastFetched = new Date()
              nextFetcher.data = data
              dispatch({ fetchers: nextFetcherState })
            },
            onError: (error: EmberApiError) => {
              nextFetcher.shouldFetch = false
              nextFetcher.fetching = false
              nextFetcher.errorMessage = error.message
              dispatch({ fetchers: nextFetcherState })
            },
          })
        }
      })
    },
    Object.keys(state.fetchers)
      .sort()
      .map((fetcherId) => state.fetchers[fetcherId].shouldFetch)
  )

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          {children}
        </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
    </QueryClientProvider>
  )
}

/**
 * Utility function that does two things:
 *
 * - returns the specified `ItemFetcher`, which gives access to global data that is fetched on
 *   demand, then stored in global state
 *
 * - if the specified fetcher has not been fetched yet, request that it be fetched, unless
 *   explicitly told not to by setting shouldFetch=false
 *
 *
 * Code that requires some globally stored data, such as the list of all our Locations, can call
 * this function like this:
 *
 *     const { data: locations } = useGlobalFetcher("locations")
 *
 * And will have the list of Location objects in variable `locations`. If the list wasn't already
 * fetched, `locations` will be `undefined`, and a request to fetch it will be sent off immediately.
 * Once the data arrives, a re-render will be triggered, and the variable will hold the data.
 *
 *
 * The returned value is an `ItemFetcher` instance, which also provides various bits of metadata
 * such as the time that the data was last fetched, and the error, if any, that was encountered when
 * fetching:
 *
 *     const locationFetcher = useGlobalFetcher("locations")
 *     if (locationFetcher.errorMessage) {
 *         ....
 *
 */
export function useGlobalFetcher<K extends keyof FetchersType>(
  fetcherId: K,
  { shouldFetch = true }: { shouldFetch?: boolean } = {}
): FetchersType[K] {
  const {
    fetchers: { [fetcherId]: fetcher },
  } = useGlobalState()
  const dispatch = useGlobalDispatch()

  useEffect(() => {
    if (
      shouldFetch &&
      (!fetcher.lastFetched || fetcher.errorMessage) &&
      typeof dispatch == "function" // in Jest tests `dispatch` is just `{}`
    ) {
      dispatch({ shouldFetch: fetcherId })
    }
  }, [fetcherId, shouldFetch])

  return fetcher
}

/**
 * This is a drop-in replacement for `React.useState` that also caches the state in localStorage or
 * sessionStorage, so it remembers its state between mounts of the components (and, if localStorage
 * is used, across sessions). The value has to be JSON-compatible.
 *
 * This is unrelated to the global state stuff above, but a file called `utils/state-utils.tsx` was
 * really the only logical place to put it.
 */
export function useCachedState<T>(
  cacheKey: string,
  defaultValue: T,
  storage: "localStorage" | "sessionStorage" = "sessionStorage"
): [T, (v: T) => void] {
  const [state, setState] = useState<T>(() => {
    const defaultFromStorage = window[storage].getItem(cacheKey)
    return defaultFromStorage ? JSON.parse(defaultFromStorage) : defaultValue
  })

  useEffect(() => {
    window[storage].setItem(cacheKey, JSON.stringify(state))
  }, [state])

  return [state, setState]
}
