// @ts-strict-ignore
import moment from "moment-timezone"

import { Leg, LegType } from "types/leg"
import { Pass, PassStatus, Tickets } from "types/pass"
import { QuoteType } from "types/quote"
import { SearchParamsType } from "types/search"

import { arrayToString } from "./string-utils"

export function getReservationsStringForValidTickets(passes: Pass[]): string {
  const validTickets: Tickets = {
    adult: 0,
    concession: 0,
    child: 0,
    youngChild: 0,
    wheelchair: 0,
    bicycle: 0,
  }
  passes.forEach((pass) => {
    for (const [ticketType, ticketCount] of Object.entries(
      getValidTickets(pass)
    )) {
      if (!validTickets[ticketType]) {
        validTickets[ticketType] = 0
      }
      validTickets[ticketType] += ticketCount
    }
  })
  return reservationsString(validTickets)
}

export function getValidTickets(pass: Pass): Tickets {
  const scheduledTransitLeg = getScheduledTransitLeg(pass.legs)

  if (pass.status === PassStatus.VALID) {
    if (scheduledTransitLeg.boardingData?.checkedIn) {
      return scheduledTransitLeg.boardingData.checkedIn
    } else {
      return pass.tickets
    }
  }

  return {
    adult: 0,
    child: 0,
    youngChild: 0,
    concession: 0,
    bicycle: 0,
    wheelchair: 0,
  }
}

export function hasValidTickets(pass: Pass): boolean {
  const tickets = getValidTickets(pass)
  return (
    tickets.adult +
      tickets.concession +
      tickets.child +
      tickets.youngChild +
      tickets.wheelchair +
      tickets.bicycle >
    0
  )
}

export function hasNoShows(pass: Pass): boolean {
  const scheduledTransitLeg = getScheduledTransitLeg(pass.legs)
  if (scheduledTransitLeg.boardingData?.noShow) {
    const { adult, child, youngChild, concession, bicycle, wheelchair } =
      scheduledTransitLeg.boardingData.noShow
    return adult + child + youngChild + concession + bicycle + wheelchair > 0
  }
  return false
}

export function getTripDeparture(trip): string {
  return trip.legs
    ? trip.legs[0].departure.scheduled
    : trip.origin.departure.scheduled
}

export function isExpectedOnTime(quote: QuoteType): boolean {
  return isExpectedToDepartOnTime(quote) && isExpectedToArriveOnTime(quote)
}

export function isExpectedToDepartOnTime(quote: QuoteType): boolean {
  return (
    !quote.legs[0].departure.estimated ||
    Math.abs(
      moment(quote.legs[0].departure.estimated).diff(
        getTripDeparture(quote),
        "seconds"
      )
    ) < 60
  )
}

function isExpectedToArriveOnTime(quote: QuoteType): boolean {
  return (
    !quote.legs[quote.legs.length - 1].arrival.estimated ||
    Math.abs(
      moment(quote.legs[quote.legs.length - 1].arrival.estimated).diff(
        quote.legs[quote.legs.length - 1].arrival.scheduled,
        "seconds"
      )
    ) < 60
  )
}

function totalPassengersForPass(pass: Pass): number {
  const tickets = getValidTickets(pass)
  return tickets.adult + tickets.concession + tickets.child + tickets.youngChild
}

function totalExtrasForPass(pass: Pass): number {
  const tickets = getValidTickets(pass)
  return tickets.wheelchair + tickets.bicycle
}

export function totalPassengersForAllPasses(passes: Pass[]): number {
  let totalPassengers = 0
  for (const index in passes) {
    totalPassengers += totalPassengersForPass(passes[index])
  }
  return totalPassengers
}

export function totalExtrasForAllPasses(passes: Pass[]): number {
  let totalExtras = 0
  for (const index in passes) {
    totalExtras += totalExtrasForPass(passes[index])
  }
  return totalExtras
}

export default function reservationsString(tickets: Tickets): string {
  const result = arrayToString([
    { value: tickets.adult, singular: "adult", plural: "adults" },
    {
      value: tickets.concession,
      singular: "concession",
      plural: "concessions",
    },
    { value: tickets.child, singular: "child", plural: "children" },
    {
      value: tickets.youngChild,
      singular: "young child",
      plural: "young children",
    },
    {
      value: tickets.wheelchair,
      singular: "wheelchair space",
      plural: "wheelchair spaces",
    },
    { value: tickets.bicycle, singular: "bike space", plural: "bike spaces" },
  ])
  return result ? result : "No bookings"
}

export function reservationsStringForPasses(passes: Pass[]): string {
  if (passes.length == 0 || totalPassengersForAllPasses(passes) == 0) {
    return "No bookings"
  } else {
    const summedTickets: Tickets = {
      adult: 0,
      child: 0,
      youngChild: 0,
      concession: 0,
      bicycle: 0,
      wheelchair: 0,
    }
    passes.map((pass) => {
      for (const [ticketType, ticketCount] of Object.entries(pass.tickets)) {
        if (!summedTickets[ticketType]) {
          summedTickets[ticketType] = 0
        }
        summedTickets[ticketType] += ticketCount
      }
    })
    return reservationsString(summedTickets)
  }
}

export function comparePasses(a: { pass: Pass }, b: { pass: Pass }): number {
  return a.pass.legs[0].departure.scheduled < b.pass.legs[0].departure.scheduled
    ? -1
    : 1
}

export function getScheduledTransitLeg(legs: Leg[]): Leg {
  return legs.find((leg) => leg.type === LegType.SCHEDULED_TRANSIT)
}

export function passIsActive(pass: Pass): boolean {
  const scheduledTransitLeg = getScheduledTransitLeg(pass.legs)
  if (pass.status == PassStatus.CANCELLED) {
    return false
  } else if (hasNoShows(pass) && !hasValidTickets(pass)) {
    // All the passengers on the pass were no-shows, the pass counts as inactive.
    return false
  } else if (scheduledTransitLeg.arrival.actual == null) {
    return true
  } else if (
    moment(scheduledTransitLeg.arrival.actual) > moment().subtract(1, "hour")
  ) {
    return true
  } else {
    return false
  }
}

export function getTicketsFromSearchParams(
  searchParams: SearchParamsType
): Tickets {
  return {
    adult: searchParams.adult,
    child: searchParams.child,
    youngChild: searchParams.youngChild,
    concession: searchParams.concession,
    wheelchair: searchParams.wheelchair,
    bicycle: searchParams.bicycle,
  }
}

export function getPassesAtStop(
  passes: Pass[],
  locationTimeId: number
): [Pass[], Pass[]] {
  const gettingOn = passes.filter(hasValidTickets).filter((pass) => {
    const scheduledTransitLeg = getScheduledTransitLeg(pass.legs)
    return scheduledTransitLeg.origin.locationTimeId === locationTimeId
  })
  const gettingOff = passes.filter(hasValidTickets).filter((pass) => {
    const scheduledTransitLeg = getScheduledTransitLeg(pass.legs)
    return scheduledTransitLeg.destination.locationTimeId === locationTimeId
  })
  return [gettingOn, gettingOff]
}
