import { Location } from "types/location"
import { Unsubscribable } from "types/observable"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"

import { EmberApiError, parseError } from "./errors"

interface LocationSearchRequest {
  type?: string
  includeOrigins?: boolean
  includeDestinations?: boolean
}

interface fetchLocationsParams {
  request: LocationSearchRequest
  onSuccess: (locations: Location[]) => void
  onError: (error: EmberApiError) => void
}

function sortLocationsByRegionAndDetailedName(locations: Location[]) {
  const sorted = locations.sort(function (a, b) {
    // Make sure sort is case insensitive
    const titleA = a.regionName.toLowerCase()
    const descriptionA = a.detailedName.toLowerCase()
    const titleB = b.regionName.toLowerCase()
    const descriptionB = b.detailedName.toLowerCase()
    return titleA < titleB
      ? -1
      : titleA > titleB
      ? 1
      : descriptionA < descriptionB
      ? -1
      : 1
  })
  return sorted
}

export function fetchLocations({
  request = {
    type: "all",
    includeOrigins: true,
    includeDestinations: true,
  },
  onSuccess,
  onError,
}: fetchLocationsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/locations/${getQueryString(request)}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(sortLocationsByRegionAndDetailedName(response))
    } else {
      onError(parseError(response))
    }
  })
}
