import { Auth } from "@aws-amplify/auth/lib/Auth"

// We keep a copy of the global state and `dispatch` function in a global
// variable here, so that functions that are not React components, and
// therefore don't have access to hooks like `useGlobalDispatch`, can still
// call `ensureLoggedOut`.
//
// It feels like working against React, maybe there's a better solution?
//
// StateType - import avoided due to problematic module structure.
let _globalState: any
let _globalDispatch: (update: any) => void = () => null

export async function ensureLoggedOut() {
  if (_globalState.loggedIn) {
    await Auth.signOut()
    _globalDispatch({
      loggedIn: false,
      profile: null,
      groups: null,
      balance: null,
      loggedInPersonUid: null,
    })
  }
}

export function setGlobalState(state: any) {
  _globalState = state
}

export function setGlobalDispatch(dispatch: (update: any) => void) {
  _globalDispatch = dispatch
}
