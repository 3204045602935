const emberTheme = {
  brandColor: "#4F917A",
  brandColorActive: "#45816c",
  palette: {
    morning: {
      veryEarly: "#fef9e1",
      early: "#ffe775",
      normal: "#f0c500",
    },
    afternoon: {
      early: "#92dea1",
      normal: "#2e823e",
    },
    evening: {
      normal: "#3c6cff",
      late: "#100061",
    },
  },
}

export default emberTheme
